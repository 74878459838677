<template>
   <section class="cont reserveSummary">
      <!-- 面包屑 preSale-state -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-if="resCupData !== 'reserve'">业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>{{resCupData === 'reserve' ? 'eBooking' : '预售券'}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框  -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>日期：</label>
                  <el-button-group>
                     <el-button :class="dataStatus === 'YESTERDAY' ? 'bg-gradient' : ''" @click="changeDataStatus('YESTERDAY')"><span>昨天</span></el-button>
                     <el-button :class="dataStatus === 'WEEK' ? 'bg-gradient' : ''" @click="changeDataStatus('WEEK')"><span>本周</span></el-button>
                     <el-button :class="dataStatus === 'MONTH' ? 'bg-gradient' : ''" @click="changeDataStatus('MONTH')"><span>本月</span></el-button>
                     <el-button :class="dataStatus === 'LASTMONTH' ? 'bg-gradient' : ''" @click="changeDataStatus('LASTMONTH')"><span>上月</span></el-button>
                     <el-button :class="dataStatus === 'CUSTOMDATE' ? 'bg-gradient' : ''" @click="changeDataStatus('CUSTOMDATE')"><span>自定义</span></el-button>
                  </el-button-group>
               </el-row>
               <el-row class="search-item">
                  <el-date-picker
                        v-if="dataStatus === 'CUSTOMDATE'"
                        v-model="pickerTime" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions" @change="handleTime">
                  </el-date-picker>
               </el-row>
               <el-row class="search-item">
                  <label>状态：</label>
                  <el-select v-model="status" clearable placeholder="全部" v-if="resCupData === 'preSale'">
                     <el-option
                           v-for="(item,index) in dictData['preSale-state@reservesummary']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
                  <el-select v-model="roomstatus" clearable placeholder="全部" v-if="resCupData === 'reserve'">
                     <el-option
                           v-for="item in orderStatusList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="searchEvent">搜索</el-button>
               <el-button  type="primary" plain @click="handleReset">重置</el-button>
               <el-button  type="primary" plain @click="exportEvent">导出</el-button>
            </el-row>
         </el-row>
         <ul class="title-box">
            <li>{{title}}表</li>
            <li>起止时间：{{queryBuyingTime}} 至 {{queryBuyingEndTime}}
               状态：<span v-if="resCupData === 'preSale'">{{statusText | filterstate(that)}}</span>
               <span v-if="resCupData === 'reserve'">{{roomstatusText | filterroomTypeState}}</span>
            </li>
         </ul>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-table v-loading="tableLoad" border fit :data="tableData" :stripe="true" ref="singleTable" highlight-current-row>
               <el-table-column :label="resCupData === 'preSale' ? '预售券项目' : '预定单项目'" prop="mainTitle" ></el-table-column>
               <el-table-column label="数量" prop="saleCount"></el-table-column>
               <el-table-column label="金额">
                  <template slot-scope="scope">
                     <span v-if="resCupData === 'preSale'"> {{scope.row.sumPrice/100}} </span>
                     <span v-if="resCupData === 'reserve'"> {{scope.row.sumPrice}} </span>
                  </template>
               </el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="checkEvent(scope.row.couponId,scope.$index)">
                        <el-link type="primary">详情</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
      </el-row>
      <el-dialog title="详情" :visible.sync="isShow" width="70%" :before-close="handleClose" v-if="isShow" >
         <el-table v-loading="checkloaling" border fit :data="checkTableData" :stripe="true">
            <el-table-column label="序号" type="index" width="50"></el-table-column>

            <template v-if="resCupData === 'preSale'">
               <el-table-column label="订单编号" prop="orderId"></el-table-column>
               <el-table-column label="预售券名称" prop="mainTitle"></el-table-column>
               <el-table-column label="下单时间" prop="buyingTime"></el-table-column>
               <el-table-column label="价格">
                  <template slot-scope="scope">
                     {{scope.row.presellPrice/100}}
                  </template>
               </el-table-column>
               <el-table-column label="手机号"  prop="mobile"></el-table-column>
               <el-table-column label="购买渠道">
                  <template slot-scope="scope">
                     {{scope.row.orderSource |  filterOrderSource(that)}}
                  </template>
               </el-table-column>
               <el-table-column label="状态">
                  <template slot-scope="scope">
                     {{scope.row.state | filterstate(that)}}
                  </template>
               </el-table-column>
            </template>

            <template v-if="resCupData === 'reserve'">
               <el-table-column label="预订人/订单编号" min-width="120">
                  <template slot-scope="scope">
                     <p>{{ scope.row.reserveName }}</p>
                     <p>{{ scope.row.orderNo }}</p>
                  </template>
               </el-table-column>
               <el-table-column label="房型" prop="roomTypeName"></el-table-column>
               <el-table-column label="预定时间" prop="createTime"></el-table-column>
               <el-table-column label="实付金额" prop="actualAmount"></el-table-column>
               <el-table-column label="手机号" prop="reserveMobile"></el-table-column>
               <el-table-column label="预定渠道">
                  <template slot-scope="scope">
                     {{ scope.row.orderSource | filterOrderSource(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="状态">
                  <template slot-scope="scope">
                  {{ scope.row.orderStatus | filterroomTypeState }}
                  </template>
               </el-table-column>
            </template>

         </el-table>
         <el-pagination layout="prev, pager, next" :total="total" class="pag-box" @size-change="pageChange"
                        :current-page="1" :pager-count="5" @current-change="handleCurrentChange">
         </el-pagination>
         <div slot="footer" class="dialog-footer" >
            <el-button class="bg-gradient" type="primary" @click="handleClose" >关闭</el-button>
         </div>
      </el-dialog>

   </section>
</template>

<script>
import { mapState } from 'vuex'
import {urlObj } from '@/api/interface';
import { eBooking } from "@/api/interface/eBooking"
import {preSale , order} from "@/api/interface/business";
import { dateFactory, exportFile, getDict } from '@/common/js/common'
export default {
   name: "reserveSummary",
   data(){
      return{
         that:this,
         resCupData:"",          // 页面
         dataStatus:"YESTERDAY", // 日期状态
         status:'',              // 状态
         roomstatus:'',          //房态状态
         statusText:'',
         roomstatusText:'',

         orderStatusList:[
            { label: '待确认', value: 'HAVE_PAID' },
            { label: '已确认', value: 'CONFIRM' },
            { label: '已拒单', value: 'DECLINE' },
            { label: '已入住', value: 'CHECKIN' },
            { label: '已取消', value: 'CANCEL' }
         ],
         title:'',               // 页面文本
         urlId:'',            // 优惠券id

         pickerTime:[],     //自定义时间
         pickerOptions: {
            disabledDate(time) {
               return time.getTime() > Date.now()-1 * 24 * 3600 * 1000;
            }
         },
         queryBuyingTime:"",     //起止时间
         queryBuyingEndTime:"",
         yesterday:'',           // 昨日
         getWeekList:'',         // 本周

         tableData:[],           // 表格数据
         tableLoad:true,         // 表格加载效果

         isShow:false,           // 弹窗是否显示
         checkTableData:[],      // 弹窗表格数据
         checkloaling:true,      // 弹窗表格加载效果

         total:0,               //总条目数
         page: 1,         // 当前页 默认第一页
         limit: 0,        // 每页显示数
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
   },
   watch:{
      $route(to) {
         if (to.name === 'resCupSummary'){
            this.resCupData = this.$route.params.resCupData
         }
      },
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getList()
            }
         },
         deep: true
      }
   },
   created() {
      sessionStorage.removeItem('dictData')
      getDict(['preSale-state','order-source'],true,
            {'preSale-state@reservesummary':["LOCK","UNCLAIMED"]})
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.resCupData = this.$route.params.resCupData
      switch (this.resCupData){
         case 'reserve' :
            this.title = '客房预定单汇总'
            break;
         case 'preSale' :
            this.title = '预售券汇总'
            break;
      }
      this.yesterday = dateFactory.getDistanceToday(-1,false)
      this.queryBuyingTime = this.yesterday
      this.queryBuyingEndTime = this.yesterday
      this.getWeekList = dateFactory.getWeekList()
      this.getList()
   },
   methods:{
      // 获取表格数据
      getList(){
         let params = { hotelId:this.hotelInfo.id}
         let RequestURL = ''
         this.tableData = []
         if(this.resCupData === 'preSale'){
            RequestURL = preSale.getCouponPresellSalesInfo
            params.queryBuyingTime = this.queryBuyingTime
            params.queryBuyingEndTime = this.queryBuyingEndTime
            params.state = this.status
         }else {
            RequestURL = eBooking.orderHotelSummary
            params.beginTime =  this.queryBuyingTime + " 00:00:01"
            params.endTime = this.queryBuyingEndTime + " 23:59:59"
            params.orderStatus = this.roomstatus
            params.roomTypeSource = "EBOOKING"
         }
         const url = RequestURL
         this.$axios.post(url, params,'json').then((res) => {
            if(res.success){
               this.tableLoad = false
               if(this.resCupData === 'preSale'){
                  this.tableData = res.records
               }else{
                  let data = res.records
                  let arr = []
                  data.forEach(item=>{
                     arr.push(
                        Object.assign({
                           mainTitle:item.roomTypeName,
                           saleCount:item.roomTypeCount,
                           sumPrice:item.payAmount,
                           couponId:item.id // 房型id
                        })
                     )
                  })
                  // let saleCount = arr.reduce((prev,curr)=>{
                  //    return Number(prev)+Number(curr.saleCount)
                  // },0)
                  // let sumPrice = arr.reduce((prev,curr)=>{
                  //    return Number(prev)+Number(curr.sumPrice)
                  // },0)
                  // arr.unshift({
                  //    mainTitle:'客房预定单总数',
                  //    saleCount:saleCount,
                  //    sumPrice:sumPrice,
                  //    couponId:''// 房型id
                  // })
                  this.tableData = arr
               }
               this.$refs.singleTable.setCurrentRow(this.tableData[0]); // 设置表格第一行高亮
            }
         })
      },
      // 日期
      changeDataStatus(state){
         if(!state) {
            this.dataStatus = "YESTERDAY"
         }else{
            this.dataStatus = state
         }
        switch (this.dataStatus){
            case 'YESTERDAY' :
               this.queryBuyingTime = this.yesterday
               this.queryBuyingEndTime = this.yesterday
               break;
           case 'WEEK' :
              this.queryBuyingTime = this.getWeekList[0]
              this.queryBuyingEndTime = this.getWeekList[6]
              break;
           case 'MONTH' :
              this.queryBuyingTime = this.getMonth('now')[0]
              this.queryBuyingEndTime = this.getMonth('now')[1]
              break;
           case 'LASTMONTH' :
              this.queryBuyingTime = this.getMonth('last')[0]
              this.queryBuyingEndTime = this.getMonth('last')[1]
              break;
           case 'CUSTOMDATE' :
              this.pickerTime[0] = this.queryBuyingTime
              this.pickerTime[1] =  this.queryBuyingEndTime
              break;
        }
         if(this.dataStatus !== 'CUSTOMDATE') {
            this.getList() // 初次自定义时不需要请求数据
         }
      },
      //营业时间发生变化时触发
      handleTime(val){
         if( this.dataStatus === 'CUSTOMDATE'){
            this.queryBuyingTime = this.pickerTime[0]
            this.queryBuyingEndTime = this.pickerTime[1]
            this.getList()
         }
      },
      // 搜索
      searchEvent(){
         this.statusText = this.status
         this.roomstatusText = this.roomstatus
         this.getList()
      },
      // 重置
      handleReset() {
         this.dataStatus = "YESTERDAY"
         if(this.resCupData === 'preSale'){
            this.status = ''
            this.statusText = ''
         }else{
            this.roomstatus = ''
            this.roomstatusText = ''
         }
         this.changeDataStatus()
      },
      //导出事件
      exportEvent() {
         let requestURL = ''
         let fileName = ''
         let param = {
            hotelId:this.hotelInfo.id,
         }
         if(this.resCupData === 'reserve'){
            requestURL = eBooking.exportOrderHotelSummary
            param.beginTime = this.queryBuyingTime + ' 00:00:01'
            param.endTime = this.queryBuyingEndTime + ' 23:59:59'
            param.orderStatus = this.roomstatus
            param.roomTypeSource = "EBOOKING"
            fileName = '客房预定单汇总'
         }else{
            requestURL = preSale.getCouponPresellSalesExcel
            param.queryBuyingTime = this.queryBuyingTime
            param.queryBuyingEndTime = this.queryBuyingEndTime
            param.state = this.status
            fileName = '预售券汇总'
         }
         const url = requestURL
         this.$axios.get(url, param, {}, 'blob').then(res => {
            exportFile(res,fileName)
         }).catch(err=>{
            console.log(err)
            this.$message.error(err.message)
         })
      },
      // 详情
      checkEvent(id,index){
         let url = ''
         let param = {}
         if(id !== 'page') this.urlId = id
         if(index === 0) this.urlId = ''
         if(this.resCupData === 'reserve'){
            let roomstatus = ''
            if(this.roomstatus === '') {
               roomstatus = 'HAVE_PAID,CONFIRM,DECLINE,CHECKIN,CANCEL'
            }else{
               roomstatus = this.roomstatus
            }
            url = order.hotelOrder +`?limit=${this.limit}&page=${this.page}`
            param.hotelId = this.hotelInfo.id
            param.orderType = 'E_BOOKING'
            param.roomTypeId = this.urlId       //房型id
            param.orderStatusList = roomstatus  //状态
            param.beginTime  = this.queryBuyingTime + ' 00:00:01'
            param.endTime = this.queryBuyingEndTime + " 23:59:59"
         }else{
            url = preSale.couponMyselfPage +`?limit=${this.limit}&page=${this.page}`
            param.couponId = this.urlId
            param.hotelId = this.hotelInfo.id
             param.beginTime  = this.queryBuyingTime + ' 00:00:01'
             param.endTime = this.queryBuyingEndTime + " 23:59:59"
         }
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.isShow = true
               this.checkloaling = false
               this.checkTableData = res.records
               this.total = res.total
            }
         })
      },
      // 关闭
      handleClose() {
         this.isShow = false
         this.checkloaling = true
         this.page = 1
         this.limit = sessionStorage.getItem('pageSize') * 1
      },
      //改变当前页
      handleCurrentChange(num){
         this.page = num
         this.checkEvent('page')
      },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.checkEvent('page')
      },
      //获取 本月now 上月last的开始日期+结束日期
      getMonth(title){
         let date = new Date();
         let Year = date.getFullYear();
         let Month = date.getMonth()
         if(title === 'last'){
            if(Month == 0){
               Month = 12
               Year = Year-1
            }
            if(Month<10) Month = '0'+Month;
         }if(title === 'now'){
            Month = date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
         }
         let monthDaynum = new Date(Year, Month, 0).getDate() //本月天数
         let arr = [Year + '-' + Month + '-' + "01",Year + '-' + Month + '-' + monthDaynum]
         return arr;
      },
   },
   filters: {
      filterstate(val,that) {
         if(val === '') return '全部'
         if (val && that.dictData['preSale-state']) return that.dictData['preSale-state'][val] || ''
      },
      filterroomTypeState(val){
         switch (val){
            case '':
               return '全部'
            case 'HAVE_PAID':
               return '待确认'
            case 'CONFIRM':
               return '已确认'
            case 'DECLINE':
               return '已拒单'
            case "CHECKIN":
               return '已入住'
            case 'CANCEL':
               return '已取消'
         }
      },
      filterOrderSource(val,that){
         if (val && that.dictData['order-source']) return that.dictData['order-source'][val] || ''
      }

   }

}
</script>

<style scoped lang="scss">
   .reserveSummary{
      .title-box{
         list-style: none;
         text-align: center;
         margin-top: 36px;
         li{
            line-height: 36px;
         }
         li:first-child{
            font-size: 25px;
         }
      }
      .pag-box{
         float: right;
         margin-top: 5px;
      }
   }
</style>
